@import "vars";
@import "assets-steen/scss/_all-blocks";

@import "~@fortawesome/fontawesome-pro/css/all.css";
@import "~@ng-select/ng-select/themes/material.theme.css";
@import "~bootstrap/scss/bootstrap";
@import "assets/scss/material";
@import "assets/scss/table";

body {
    font-family: 'Roboto';
    background: none;
}

.curpoint {
    cursor: pointer;
}

.spacer {
    flex: 1 1 auto;
}

.error-background {
    background-color: $error_color;
    color: #FFFFFF !important;

    .mat-select-value-text {
        color: #FFFFFF !important;
    }

    .material-piece {
        color: #FFFFFF !important;
    }
}

.warn-color {
    color: orange;
}

.dialog-title {
    font-size: 22px;
}

.mat-form-field-type-mat-select, .mat-select-panel {
    user-select: none;
}

button:focus {
    outline: 0;
}

.mat-raised-button {
    border-radius: 0 !important;
    box-shadow: none !important;
    padding-left: 30px !important;
    padding-right: 30px !important;

    .mat-button-focus-overlay, .mat-button-ripple.mat-ripple {
        border-radius: 0 !important;
    }

    &.mat-secondary {
        background: $blue_color;
        color: #FFFFFF;
    }
}

mat-form-field {
    .mat-select-disabled .mat-select-value, .mat-input-element:disabled, .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
        color: #000;
    }
}

.ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-value {
    color: #000;
}

mat-hint {
    &.warning {
        color: $orange;
        @include warningBefore();
    }
}

h1 {
    font-size: 24px;
}

h2 {
    font-size: 22px;
}

h3 {
    font-size: 20px;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 16px;
}

mat-dialog-container.mat-dialog-container {
    height: auto !important;
    padding: 15px 15px 2px 15px !important;

    .mat-dialog-content {
        margin: 0 -15px;
    }
}

.mat-dialog-title {
    margin: -15px -15px 0 0 !important;

    .title {
        color: $main_color;
    }
}

.number-input {
    position: relative;

    .available-trucks {
        position: absolute;
        top: 0;
        right: 2px;
        padding: 0;
        font-size: 14px;
    }

    &.warning {
        background: $orange;
        color: #FFFFFF;
    }
}

.mat-tooltip {
    pointer-events: none;
    font-size: 14px;
    font-weight: normal;
    color: #000000 !important;
    border: 1px solid $main_color;
    border-radius: 0 !important;
    background-color: #F3F2F7;
}

mat-tooltip-component, .mat-tooltip-panel {
    pointer-events: none;
}

mat-form-field textarea.mat-input-element {
    resize: none;
}


table {
    input, mat-select {
        border-bottom: 2px solid transparent !important;
    }

    input:focus, mat-select:focus {
        border-bottom: 2px solid $main_color;
    }
}

.mat-autocomplete-panel .mat-option.mat-selected:not(:hover) {
    background: rgba(0, 0, 0, 0.2);
}

.mat-form-field.mat-form-field-appearance-fill .mat-form-field-subscript-wrapper {
    padding: 0 2px;
}


.loading-overlay-dialog {
    .mat-dialog-container {
        transform: none;
        background: transparent;
        width: 250px;
        height: 250px;
        overflow: hidden;
        box-shadow: none;
    }
}

.ucfirst {
    text-transform: capitalize;
}

@media print {
    @page {
        size: A4 landscape;
        margin: 20px 15px 0 15px;
    }

    body {
        font-size: 14px;
    }
    .table tr th {
        padding: 0.2rem 0.5rem;
    }

}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after,
.mat-select-disabled,
.mat-select-disabled .mat-select-value {
    color: rgba(0, 0, 0, 0.8);
    background: #F3F2F7;
}

table.disabled {
    td, .timepicker {
        background: #F3F2F7;
        color: rgba(0, 0, 0, 0.8);
    }

    .number-input.warning .available-trucks {
        color: $orange;
    }
}

.text-red, .text-red .mat-select-value {
    color: $error_color;
}

.material-piece {
    margin: 0 3px;
    font-weight: 700;
    color: $error_color;
}

.mat-tooltip {
    white-space: pre-line !important;
}

.time-edit-dialog, .comment-edit-dialog {
    > .mat-dialog-container {
        padding: 0 !important;
    }
}

.team-selector {
    position: absolute;
    left: 300px;

    mat-form-field {
        width: 270px;
    }


    ::ng-deep {
        label {
            top: 1.6rem;
        }

        .mat-select-value-text {
            font-size: .8em;
        }
    }
}

.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    min-width: 0;
    white-space: nowrap;
    max-width: 100%;
}


td.select {
    padding: .2rem .3rem !important;
    font-size: .9rem;

    ng-select {
        width: 160px;
        min-width: 100%;
        padding-bottom: 0;

        .ng-select-container {
            min-height: 35px;

            &:after {
                display: none;
            }

            .ng-value-container {
                padding-bottom: 0;
                padding-top: .2em;

                .ng-input {
                    bottom: 0;
                }
            }

            .ng-clear-wrapper, .ng-arrow-wrapper {
                bottom: 2px !important;
            }

            .ng-clear-wrapper {
                margin-bottom: -4px;
            }

        }

        .ng-dropdown-panel.ng-select-bottom {
            top: calc(100% + 0.25em);
        }

        .ng-dropdown-panel {
            width: auto !important;
            max-width: 550px;
            min-width: 100%;
        }

        .ng-has-value .ng-placeholder, &.ng-select-opened .ng-placeholder {
            transform: translateY(-.9em) scale(0.75) perspective(100px) !important;
        }

    }
}

.ng-select-fill.ng-select {
    &.ng-select-single {
        .ng-select-container .ng-value-container .ng-input {
            > input {
                padding: 0 0.75em;
            }
        }
    }

    .ng-select-container {
        background-color: $gray_color;
        border-radius: 4px 4px 0 0;
        padding: 0.719em 0.75em 0 0.75em;
        min-height: 59.50px;

        .ng-value-container {
            padding: 0.0875em 0 0 0;
        }

        .ng-arrow-wrapper {
            bottom: 20px;
        }

        .ng-clear-wrapper {
            bottom: 16px;
        }

        &.ng-has-value {

            .ng-placeholder {
                transform: translateY(-17px) scale(0.75) perspective(100px) translateZ(0px);

            }
        }
    }
}

a.mat-raised-button {
    text-decoration: none;
}

.toolbox-pdf-dialog {
    .mat-dialog-container {
        padding: 0 !important;
    }
}

@media print {
    .button-wrapper {
        display: none;
    }

    .list-item {
        page-break-inside: avoid;
        page-break-after: auto;
    }

}
